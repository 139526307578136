import { useState } from 'react'
import {
    Progress,
    Box,
    ButtonGroup,
    Button,
    Heading,
    Flex,
    FormControl,
    GridItem,
    FormLabel,
    Input,
    Select,
    InputGroup,
    FormHelperText,
    InputLeftElement,
    Radio,
    Stack,
    RadioGroup,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import {MdInfoOutline, MdVpnKey} from "react-icons/md";
import Modal from "./Modal";


const Form1 = ({ loginKey, setLoginKey }) => {
    return (
        <>
            <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                Connexion
            </Heading>
            <FormControl mt="2%">
                <FormLabel htmlFor="email" fontWeight={'normal'}>
                    Clé
                </FormLabel>
                <InputGroup>
                    <Input
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        type="text"
                        value={loginKey}
                        onChange={e => setLoginKey(e.target.value)}
                    />
                    <InputLeftElement>
                        <MdVpnKey color='lightgray' />
                    </InputLeftElement>
                </InputGroup>
                <FormHelperText>Votre clé de connexion est unique.</FormHelperText>
            </FormControl>
        </>
    )
}

const Form2 = ({ formData, setFormData }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Modal imageUrl={'assets/mode_emploi.png'} isOpen={modalOpen} onClose={() => setModalOpen(false)} />
            <Stack justifyContent="center">
                <Button onClick={() => setModalOpen(true)} margin="auto" leftIcon={<MdInfoOutline />} colorScheme='pink' variant='ghost'>
                    Mode d'emploi pour la mesure avec l'eKer FIP
                </Button>
            </Stack>
            <br />
            <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                Informations
            </Heading>

            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="taille"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}
                    mt="2%">
                    Taille
                </FormLabel>
                <NumberInput
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={formData.taille}
                    onChange={value => setFormData(prev => ({...prev, taille: value}))}
                    name="taille"
                    id="taille"
                    min={0}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="nb_filles"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}
                    mt="2%">
                    Bodycount
                </FormLabel>
                <NumberInput
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={formData.nb_filles}
                    onChange={value => setFormData(prev => ({...prev, nb_filles: value}))}
                    name="nb_filles"
                    id="nb_filles"
                    min={0}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="premiere_fois"
                    fontSize="sm"
                    fontWeight="md"
                    autoComplete="off"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}
                    mt="2%">
                    Âge de la première fois
                </FormLabel>
                <NumberInput
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={formData.age_premiere_fois}
                    onChange={value => setFormData(prev => ({...prev, age_premiere_fois: value}))}
                    name="premiere_fois"
                    id="premiere_fois" min={0}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="politique"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}
                    mt="2%">
                    Orientation politique
                </FormLabel>
                <Select
                    id="politique"
                    name="politique"
                    placeholder="Sélectionner une option"
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={formData.orientation_politique}
                    onChange={e => setFormData(prev => ({ ...prev, orientation_politique: e.target.value }))}>
                    <option>Extrême gauche</option>
                    <option>Gauche</option>
                    <option>Centre</option>
                    <option>Droite</option>
                    <option>Extrême droite</option>
                </Select>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="preference"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}
                    mt="2%">
                    Ta préférence :
                </FormLabel>
                <RadioGroup
                    value={formData.preference}
                    onChange={value => setFormData(prev => ({ ...prev, preference: value }))}>
                    <Stack id="preference" spacing={5} direction='row'>
                        <Radio colorScheme='pink' value='1'>
                            Fesses
                        </Radio>
                        <Radio colorScheme='pink' value='2'>
                            Seins
                        </Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default function Multistep() {
    const toast = useToast();
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(33.33);

    const [isLoading, setIsLoading] = useState(false);
    const [loginKey, setLoginKey] = useState("");
    const [formData, setFormData] = useState({
        token: "",
        taille: 0,
        nb_filles: 0,
        age_premiere_fois: 0,
        orientation_politique: "",
        preference: "",
    });

    return (
        <>
            <Box
                bg="white"
                borderWidth="1px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                maxWidth={800}
                minWidth={{base: "90%", sm: 500}}
                p={6}
                m="10px auto"
                as="form">
                <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
                {
                    progress < 100 ? (
                        <>
                            {step === 1 ? <Form1 loginKey={loginKey} setLoginKey={setLoginKey} /> : step === 2 ? <Form2 formData={formData} setFormData={setFormData} /> : null}
                            <ButtonGroup mt="5%" w="100%">
                                <Flex w="100%" justifyContent="space-between">
                                    <Flex>
                                        <Button
                                            onClick={() => {
                                                setStep(step - 1)
                                                setProgress(progress - 50)
                                            }}
                                            isDisabled={step === 1}
                                            colorScheme="teal"
                                            variant="solid"
                                            w="7rem"
                                            mr="5%">
                                            Back
                                        </Button>
                                        { step === 1 ?
                                            <Button
                                                w="7rem"
                                                isLoading={isLoading}
                                                isDisabled={!loginKey.length}
                                                onClick={async () => {
                                                    setIsLoading(true);

                                                    const response = await fetch(process.env.REACT_APP_API_URI + `/check-login/`, {
                                                        method: "POST",
                                                        body: JSON.stringify({
                                                            login_id: loginKey,
                                                        }),
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                    });

                                                    if (response.ok) {
                                                        const result = await response.json();

                                                        if (result.exists) {
                                                            setFormData(prev => ({ ...prev, token: result.token }));
                                                            setStep(step + 1)
                                                            setProgress(progress + 33.33);
                                                        } else {
                                                            toast({
                                                                title: 'Non autorisé.',
                                                                description: "Adressez-vous à un administrateur :p",
                                                                status: 'error',
                                                                duration: 3000,
                                                                isClosable: true,
                                                            });
                                                        }
                                                    } else {
                                                        toast({
                                                            title: 'Une erreur s\'est produite.',
                                                            description: "Merci de réessayer plus tard.",
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                    }
                                                    setIsLoading(false)
                                                }}
                                                colorScheme="teal"
                                                variant="outline">
                                                Next
                                            </Button> : null
                                        }
                                        { step === 2 ?
                                            <Button
                                                w="7rem"
                                                isLoading={isLoading}
                                                colorScheme="pink"
                                                variant="outline"
                                                onClick={async () => {
                                                    setIsLoading(true);

                                                    const payload = {
                                                        token: formData.token.toString(),
                                                        taille: parseInt(formData.taille, 10),
                                                        nb_filles: parseInt(formData.nb_filles, 10),
                                                        age_premiere_fois: parseInt(formData.age_premiere_fois, 10),
                                                        orientation_politique: formData.orientation_politique,
                                                        fesses: formData.preference === '1',
                                                        seins: formData.preference === '2',
                                                    };

                                                    const response = await fetch(process.env.REACT_APP_API_URI + "/save-form/", {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify(payload)
                                                    });

                                                    if(response.ok) {
                                                        setStep(step + 1);
                                                        setProgress(100);

                                                        toast({
                                                            title: 'Réponse envoyée avec succès !',
                                                            status: 'success',
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                    } else {
                                                        // Handle error case
                                                        toast({
                                                            title: 'Erreur lors de l\'envoi de la réponse',
                                                            description: "Merci de réessayer plus tard",
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                    }
                                                    setIsLoading(false);
                                                }}>
                                                Valider
                                            </Button> : null
                                        }
                                    </Flex>
                                </Flex>
                            </ButtonGroup>
                        </>
                    ) : (
                        <>
                            <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="4%">
                                Terminé !
                            </Heading>
                            <Text textAlign="center">
                                Merci d'avoir répondu au sondage.<br />
                                Tous les résultats sont anonymes et seront uniquement étudiés à des fins statistiques.
                            </Text>
                        </>
                    )
                }
            </Box>
        </>
    )
}
