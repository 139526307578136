// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.modal-cross {
    position: absolute;
    color: white;
    font-size: 40px;
    top: -60px;
    right: 0;
}
.modal-cross:hover {
    cursor: pointer;
}

.modal-content img {
    max-height: 90vh;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,UAAU;IACV,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.8);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.modal-content {\n    border-radius: 8px;\n    position: relative;\n    max-width: 90%;\n    max-height: 90%;\n}\n\n.modal-cross {\n    position: absolute;\n    color: white;\n    font-size: 40px;\n    top: -60px;\n    right: 0;\n}\n.modal-cross:hover {\n    cursor: pointer;\n}\n\n.modal-content img {\n    max-height: 90vh;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
