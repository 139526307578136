import Multistep from "./Form";
import './App.css';
import {ChakraProvider, Stack} from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
        <Stack justifyContent="center" alignItems="center" height="100vh" bg="#fee8ff">
          <Multistep />
        </Stack>
    </ChakraProvider>
  );
}

export default App;
