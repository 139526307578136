import React from 'react';
import './Modal.css';

const Modal = ({ imageUrl, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <img src={imageUrl} alt="Modal Pic" />
                <div onClick={onClose} className="modal-cross">&times;</div>
            </div>
        </div>
    );
};

export default Modal;